import mixpanel from 'mixpanel-browser';
import { API_ENV } from './apis';
import { MIXPANEL_TOKEN } from './config';

const isDev = API_ENV === 'development';

export const PageViewEventName = {
  view_screen_project_detail: 'view_screen_project_detail',
  view_screen_project_detail_introduction:
    'view_screen_project_detail_introduction',
  view_screen_project_detail_information:
    'view_screen_project_detail_information',
  view_screen_project_detail_review: 'view_screen_project_detail_review',
} as const;
export type PageViewEventName =
  typeof PageViewEventName[keyof typeof PageViewEventName];

export const ClickEventName = {
  click_project: 'click_project',
  click_project_game_start: 'click_project_game_start',
  click_project_inventory: 'click_project_inventory',
  click_project_help: 'click_project_help',
  click_project_chatbot: 'click_project_chatbot',
  click_login_anonymous: 'click_login_anonymous',
  click_login_oauth: 'click_login_oauth',
} as const;
export type ClickEventName = typeof ClickEventName[keyof typeof ClickEventName];

export type TrackEventName = PageViewEventName | ClickEventName;

function initialize({
  userId,
  loginPlatform,
}: {
  userId?: string;
  loginPlatform?: string;
}) {
  if (isDev) {
    console.log(
      `개발 서버에서 userId: ${
        userId || '비로그인'
      } (으)로 트래킹이 initialize 되었습니다.`
    );
    return;
  }

  try {
    window.gtag('js', new Date());
    window.gtag('config', 'G-959XS0085G', {
      send_page_view: false,
      user_id: userId,
      loginPlatform,
    });

    mixpanel.init(MIXPANEL_TOKEN, { debug: isDev });

    if (loginPlatform) {
      mixpanel.register({
        userId: userId || '',
        loginPlatform,
      });
    }

    if (userId) mixpanel.identify(userId);
  } catch (e) {
    console.error(e);
  }
}

function sendGAEvent(trackEventName: TrackEventName, params?: object) {
  try {
    window.gtag('event', trackEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function sendMixpanelEvent(
  trackEventName: TrackEventName,
  params?: object,
  pageView = false
) {
  try {
    if (!pageView) {
      mixpanel.track(trackEventName, params);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mixpanel.track_pageview(params, { event_name: trackEventName });
    }
  } catch (e) {
    console.error(e);
  }
}

function onPageView({
  pageViewEventName,
  params,
}: {
  pageViewEventName: PageViewEventName;
  params?: object;
}) {
  if (isDev) {
    console.log(
      `개발 서버에서 pageViewEventName: ${pageViewEventName} (으)로 onPageView가 호출되었습니다.`
    );
    return;
  }

  try {
    sendGAEvent(pageViewEventName, params);
    sendMixpanelEvent(pageViewEventName, params, true);
  } catch (e) {
    console.error(e);
  }
}

function onClick({
  clickEventName,
  params,
}: {
  clickEventName: ClickEventName;
  params?: object;
}) {
  if (isDev) {
    console.log(
      `개발 서버에서 clickEventName: ${clickEventName} (으)로 onClick 호출되었습니다.`
    );
    return;
  }

  try {
    sendGAEvent(clickEventName, params);
    sendMixpanelEvent(clickEventName, params);
  } catch (e) {
    console.log(e);
  }
}

export const track = {
  initialize,
  onPageView,
  onClick,
};
